@use "../styles/components/functions.scss" as *;
@use "../styles/components/variables.scss" as *;
@use "../styles/components/breakpoints.scss" as *;

.header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(24);

  @include breakpoint("medium") {
    padding: unset;
    padding-left: rem(24);
  }

  @include breakpoint("large") {
    padding-top: rem(40);
    padding-left: rem(55);
  }
}

.menu-toggler {
  width: rem(25);
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 999;

  img {
    width: 100%;
    height: 100%;
  }
}

.nav {
  overflow-x: hidden;
  background: rgba($dark-blue, 0.3);
  backdrop-filter: blur(50px);
  z-index: 999;

  @include breakpoint("medium") {
    overflow-x: initial;
  }

  &.mobile.active {
    width: 60%;
    opacity: 1;
  }

  &.mobile {
    position: absolute;

    height: 100vh;
    right: 0;
    top: 0;
    width: 0;
    opacity: 0;
    transition: width 400ms ease-in-out, opacity 400ms ease-in-out;

    .nav__list {
      margin-top: rem(120);
      padding-left: rem(32);
      display: flex;
      flex-direction: column;
      gap: rem(30);
    }
  }

  &.desktop {
    background: rgba(rgb(207, 207, 207), 0.1);

    .nav__list {
      padding: rem(35);
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: rem(20);

      @include breakpoint("large") {
        gap: rem(50);
        padding: rem(35) rem(150);
      }

      @include breakpoint("xlarge") {
        gap: rem(70);
        padding: rem(35) rem(200);
      }

      &_item {
        position: relative;

        .link {
          &::after {
            content: "";
            position: absolute;
            bottom: -35px;
            left: -1px;
            width: 100%;
            height: 2px;
            background: transparent;
            transition: background 300ms ease-in-out;
          }

          &:hover::after,
          &:focus::after {
            background: rgba($white, 0.2);
          }

          &.currentlyActive::after {
            background: white;
            height: 3px;
          }
        }
      }
    }

    .counter {
      display: none;

      @include breakpoint("large") {
        display: initial;
      }
    }
  }
}

.nav__list {
  .link {
    color: $white;
    font-size: rem(16);
    text-transform: uppercase;
    letter-spacing: 2.7px;
    font-weight: 300;
    height: 100%;
  }

  .counter {
    margin-right: rem(10);
    font-size: rem(16);
    letter-spacing: 2px;
    color: $white;
    font-weight: bold;
  }
}
