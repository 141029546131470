@use "./styles/components/breakpoints.scss" as *;

body {
  background-image: url("./assets/home/background-home-mobile.jpg");
  transition: 0.7s background-image 0.5s cubic-bezier(0.17, 0.67, 0.31, 0.36);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  @include breakpoint("medium") {
    background-image: url("./assets/home/background-home-tablet.jpg");
  }

  @include breakpoint("large") {
    background-image: url("./assets/home/background-home-desktop.jpg");
  }

  &.destination {
    background-image: url("./assets/destination/background-destination-mobile.jpg");

    @include breakpoint("medium") {
      background-image: url("./assets/destination/background-destination-tablet.jpg");
    }

    @include breakpoint("large") {
      background-image: url("./assets/destination/background-destination-desktop.jpg");
    }
  }

  &.crew {
    background-image: url("./assets/crew/background-crew-mobile.jpg");

    @include breakpoint("medium") {
      background-image: url("./assets/crew/background-crew-tablet.jpg");
    }

    @include breakpoint("large") {
      background-image: url("./assets/crew/background-crew-desktop.jpg");
    }
  }

  &.technology {
    background-image: url("./assets/technology/background-technology-mobile.jpg");

    @include breakpoint("medium") {
      background-image: url("./assets/technology/background-technology-tablet.jpg");
    }

    @include breakpoint("large") {
      background-image: url("./assets/technology/background-technology-desktop.jpg");
    }
  }
}
